<template>
    <div class="help-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                <div class="col-md-10 ml-auto mr-auto">
                    <h1 class="title">Help Center</h1>
                    <h4>Need some assistance? You can find all you need to know about the DrRemo system here.</h4>
                </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <h3 class="d-flex justify-content-center p-0 m-0">I am a</h3>
                    <div class="d-flex flex-wrap justify-content-center">
                        <div class="profile-selector row justify-content-center">
                            <div class="mt-3">
                                <div class="d-flex justify-content-center">Patient</div>
                                <div class="selector info-hover" title="Patient" @click="getHelpItemsByProfileType(2)">
                                    <div class="icon icon-success icon-circle">
                                        <i class="fas fa-user-injured"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex justify-content-center">Practitioner</div>
                                <div class="selector info-hover" title="Practitioner" @click="getHelpItemsByProfileType(4)">
                                    <div class="icon icon-success icon-circle">
                                        <i class="fas fa-user-md"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex justify-content-center">Practice</div>
                                <div class="selector info-hover" title="Practice" @click="getHelpItemsByProfileType(3)">
                                    <div class="icon icon-success icon-circle">
                                        <i class="fas fa-hospital-user"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="uiModel.selectedProfileTypeId !== 0">
                        <div class="mt-5">
                            <template v-if="!uiModel.loaders.loadingHelpItems">
                                <template v-if="uiModel.helpItems.length === 0">
                                    <span class="d-flex justify-content-center">No help items found.</span>
                                </template>
                                <template v-else>
                                    <collapse>
                                        <collapse-item class="col-md-12" v-for="item in uiModel.helpItems" :key="item.id" :title="item.title" :name="item.id">
                                            <div class="content mb-2">{{ item.content }}</div>
                                            <div class="steps">
                                                <div v-for="(step, index) in item.steps" :key="step.id">

                                                                <template v-if="step.content === 'patient-user-manual' && step.helpId === 12">
                                                                    <span><b>Step {{ (index + 1) }}</b> - <a href="#" @click="userManual(2)">Browse User Manual</a></span><br />
                                                                    <span><b>Step {{ (index + 2) }}</b> - <a href="#" @click="downloadManualPDF(2,'patient-user-manual')">Download </a></span>
                                                                </template>
                                                                <template v-else-if="step.content === 'practice-user-manual' && step.helpId === 18">
                                                                    <span><b>Step {{ (index + 1) }}</b> - <a href="#" @click="userManual(3)">Browse User Manual</a></span><br />
                                                                    <span><b>Step {{ (index + 2) }}</b> - <a href="#" @click="downloadManualPDF(3,'practice-user-manual')">Download</a></span>
                                                                </template>
                                                                <template v-else-if="step.content === 'practitioner-user-manual' && step.helpId === 19">
                                                                    <span><b>Step {{ (index + 1) }}</b> - <a href="#" @click="userManual(4)">Browse User Manual</a></span><br />
                                                                    <span><b>Step {{ (index + 2) }}</b> - <a href="#" @click="downloadManualPDF(4,'practitioner-user-manual')">Download</a></span>
                                                                </template>
                                                                <template v-else-if="step.content === 'medicine-quick-code' && step.helpId === 20">
                                                                    <!-- <span><b>Step {{ (index + 1) }}</b> - <a href="#" @click="userManual(4)">Browse Quick Quote</a></span><br /> -->
                                                                    <span><b>Step {{ (index + 1) }}</b> - <a href="#" @click="downloadManualPDF(4,'medicine-quick-code')">Download</a></span>
                                                                </template>
                                                                <template v-else>
                                                                    <span><b>Step {{ (index + 1) }}</b> - {{ step.content }}</span>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div class="video">
                                                    <div v-for="video in item.videos" :key="video.id">
                                                        <iframe width="100%" height="350" :src="video.videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                    </div>                                                
                                               </div>
                                        </collapse-item>
                                    </collapse>
                                </template>
                            </template>
                            <template v-else>
                                <div class="d-flex justify-content-center">
                                    <span class="d-flex"><loading-indicator></loading-indicator>&nbsp;Loading help items</span>
                                </div>
                            </template>
                        </div>
                    </template>
                    <div class="contact-us-section">
                        <div class="separator-line separator-primary mt-10"></div>

                        <div class="section section-basic-components">
                            <div class="d-flex flex-row justify-content-center">
                                <div class="flex-column align-self-center">
                                    <card class="contactus-card text-center">
                                        <h4 class="card-title"><b>Want to know more?</b></h4>
                                        <p class="card-subtitle">If you would like to get more information, please do not hesitate to contact us <i class="far fa-smile"></i></p>
                                        <n-button type="primary" @click="goToContactUs">CONTACT US</n-button>
                                    </card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Card } from '@/components';
import { Collapse } from '@/components';
import { CollapseItem } from '@/components';
import { Button } from '@/components';

import HelpCenterService from '@/services/helpCenterService';

export default {
    name: 'help',
    bodyClass: 'help-page',
    components: {
        Card,
        Collapse,
        CollapseItem,
        [Button.name]: Button
    },
    data() {
        return {
            uiModel: {
                links : [{
                    'navigationText ' : 'navigationText ',
                    'title  ' : '<a href="UserManual/Patient.html" target="_blank">User Manual</a> ',
                }],
                selectedProfileTypeId: 0,
                helpItems: [],
                loaders: {
                    loadingHelpItems: false
                }
            }
        };
    },
    methods: {
        goToContactUs() {                
            var self = this;
            self.$router.push({name:"contactus"}); 
        },
        getHelpItemsByProfileType(profileTypeId) {
            var self = this;
            self.uiModel.loaders.loadingHelpItems = true;
            self.uiModel.selectedProfileTypeId = profileTypeId;
            self.getHelpItems();
        },
        getHelpItems() {
            var self = this;
            var helpCenterService = new HelpCenterService();
            helpCenterService.getHelpItemsByProfileType(self.uiModel.selectedProfileTypeId, self.handleHelpItemsResponse);
        },
        handleHelpItemsResponse(response) {
            var self = this;
            if(response.status === 200) {
                self.uiModel.helpItems = response.data.result;
                 console.log("Response ", response.data.result);  
                self.uiModel.loaders.loadingHelpItems = false;
            }
        },
        userManual(profileId) {
          
            if(profileId == 2){ window.open("PatientManual");}
            if(profileId == 3){ window.open("PracticeManual");}
            if(profileId == 4){ window.open("PractitionerManual");}
            // if(profileId == 4){ window.open("QuickCode");}
        },
        downloadManualPDF(profileId,file) {

            let pdfUrl = "";

            if(profileId == 2 && file == 'patient-user-manual'){pdfUrl = 'img/user-manual/Drremo-Patient-User-Manual.pdf';}
            if(profileId == 3 && file == 'practice-user-manual'){pdfUrl = 'img/user-manual/Drremo-Practice-User-Manual.pdf' ;}
            if(profileId == 4 && file == 'practitioner-user-manual'){pdfUrl = 'img/user-manual/Drremo-Practitioner-User-Manual.pdf';}
            if(profileId == 4 && file == 'medicine-quick-code'){pdfUrl = 'img/quick-codes/Medicine_Quick_Codes.xlsx';}
     
            if(file == 'patient-user-manual' || file == 'practice-user-manual' || file == 'practitioner-user-manual'){

            const link = document.createElement('a');
            link.href = pdfUrl;
            link.target = '_blank'; 
            link.setAttribute('download', 'drremo-user-manual.pdf');

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            }else{
                
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.target = '_blank'; 
            link.setAttribute('download', 'quick-codes.xlsx');

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            }
        }
    },
    metaInfo() {
        return {
            title: "DrRemo | Help Center",
            meta: [
                {
                    name: "description",
                    content: "Need some assistance? You can find all you need to know about the DrRemo system here."
                },
                {
                    name: "robots",
                    content: "index, follow"
                }
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://dr-remo.co.za/helpcenter"
                }
            ]
        }
    }
};
</script>